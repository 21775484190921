import * as React from "react";
import Layout from "../components/Layout";

const TemrmsPolicy = () => {
  return (
    <Layout>
      <div className="container">
        <section className="policy-section">
          <h1>Terms of Use</h1>
          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </p>
          <p>
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            privacy policy and Terms of Use for access or usage of domain name
            https://apexlearning.co.in/ (“Website”), including the related
            mobile site and mobile application (hereinafter referred to as
            “Platform”) The Platform is owned by Apex Learning Institute a
            company incorporated under the Companies Act, 2013 with its
            registered office SCO-24, Sector-41 D, chandigarh, 160036, India
            (hereinafter referred to as Apex Learning Institute). Your use of
            the Platform and services and tools are governed by the following
            terms and conditions (“Terms of Use”) as applicable to the Platform
            including the applicable policies which are incorporated herein by
            way of reference. If You transact on the Platform, You shall be
            subject to the policies that are applicable to the Platform for such
            transaction. By mere use of the Platform, You shall be contracting
            with Apex Learning Institute and these terms and conditions
            including the policies constitute Your binding obligations, with
            Apex Learning Institute.
          </p>
          <p>
            For the purpose of these Terms of Use, wherever the context so
            requires “You” or “User” shall mean any natural or legal person who
            has agreed to become a buyer on the Platform by providing
            Registration Data while registering on the Platform as Registered
            User using the computer systems. Apex Learning Institute allows the
            User to surf the Platform or making purchases without registering on
            the Platform. The term “We”, “Us”, “Our” shall mean Apex Learning
            Institute Private Limited.
          </p>
          <p>
            When You use any of the services provided by Us through the
            Platform, including but not limited to, (e.g. Product Reviews,
            Seller Reviews), You will be subject to the rules, guidelines,
            policies, terms, and conditions applicable to such service, and they
            shall be deemed to be incorporated into this Terms of Use and shall
            be considered as part and parcel of this Terms of Use. We reserve
            the right, at Our sole discretion, to change, modify, add or remove
            portions of these Terms of Use, at any time without any prior
            written notice to You. It is Your responsibility to review these
            Terms of Use periodically for updates / changes. Your continued use
            of the Platform following the posting of changes will mean that You
            accept and agree to the revisions. As long as You comply with these
            Terms of Use, We grant You a personal, non-exclusive,
            non-transferable, limited privilege to enter and use the Platform.
            ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
            SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By
            impliedly or expressly accepting these Terms of Use, You also accept
            and agree to be bound by Apex Learning Institute Policies (including
            but not limited to Privacy Policy available at Privacy) as amended
            from time to time.
          </p>
          <h2>Your Account and Registration Obligations</h2>
          <p>
            If You use the Platform, You shall be responsible for maintaining
            the confidentiality of your Display Name and Password and You shall
            be responsible for all activities that occur under your Display Name
            and Password. You agree that if You provide any information that is
            untrue, inaccurate, not current or incomplete or We have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, or not in accordance with the this Terms of
            Use, We shall have the right to indefinitely block access to the
            Platform and refuse to provide You with access to the Platform. Your
            mobile phone number and/or e-mail address is treated as Your primary
            identifier on the Platform. It is your responsibility to ensure that
            Your mobile phone number and your email address is up to date on the
            Platform at all times. You agree to notify Us promptly if your
            mobile phone number or e-mail address changes by updating the same
            on the Platform through a onetime password verification.
          </p>
          <p>
            You agree that Apex Learning Institute shall not be liable or
            responsible for the activities or consequences of use or misuse of
            any information that occurs under your Account in cases, including,
            where You have failed to update Your revised mobile phone number
            and/or e-mail address on the Website Platform. If You share or allow
            others to have access to Your account on the Platform (“Account”),
            by creating separate profiles under Your Account, or otherwise, they
            will be able to view and access Your Account information. You shall
            be solely liable and responsible for all the activities undertaken
            under Your Account, and any consequences therefrom.
          </p>
          <h2>Condition for Use of the Platform</h2>
          <p>
            You agree, undertake and confirm that Your use of Platform shall be
            strictly governed by the following binding principles: You shall not
            host, display, upload, modify, publish, transmit, update or share
            any information which:
          </p>
          <ul>
            <li>
              belongs to another person and to which You does not have any right
              to;
            </li>
            <li>
              is grossly harmful, harassing, blasphemous, defamatory, obscene,
              pornographic, paedophilic, libellous, invasive of another’s
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever; or unlawfully
              threatening or unlawfully harassing including but not limited to
              “indecent representation of women” within the meaning of the
              Indecent Representation of Women (Prohibition) Act, 1986;
            </li>
            <li>is misleading in any way;</li>
            <li>
              is patently offensive to the online community, such as sexually
              explicit content, or content that promotes obscenity, paedophilia,
              racism, bigotry, hatred or physical harm of any kind against any
              group or individual;
            </li>
            <li>harasses or advocates harassment of another person;</li>
            <li>
              involves the transmission of “junk mail”, “chain letters”, or
              unsolicited mass mailing or “spamming”;
            </li>
            <li>
              promotes illegal activities or conduct that is abusive,
              threatening, obscene, defamatory or libellous; (h) infringes upon
              or violates any third party’s rights [including, but not limited
              to, intellectual property rights, rights of privacy (including
              without limitation unauthorized disclosure of a person’s name,
              email address, physical address or phone number) or rights of
              publicity];
            </li>
            <li>
              promotes an illegal or unauthorized copy of another person’s
              copyrighted work (see “Copyright complaint” below for instructions
              on how to lodge a complaint about uploaded copyrighted material),
              such as providing pirated computer programs or links to them,
              providing information to circumvent manufacture-installed
              copy-protect devices, or providing pirated music or links to
              pirated music files;
            </li>
            <li>
              contains restricted or password-only access pages, or hidden pages
              or images (those not linked to or from another accessible page);
            </li>
            <li>
              provides material that exploits people in a sexual, violent or
              otherwise inappropriate manner or solicits personal information
              from anyone;
            </li>
            <li>
              provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone’s privacy,
              or providing or creating computer viruses;
            </li>
            <li>
              contains video, photographs, or images of another person (with a
              minor or an adult).
            </li>
            <li>
              tries to gain unauthorized access or exceeds the scope of
              authorized access to the Platform or to profiles, blogs,
              communities, account information, bulletins, friend request, or
              other areas of the Platform or solicits passwords or personal
              identifying information for commercial or unlawful purposes from
              other users;
            </li>
            <li>
              engages in commercial activities and/or sales without Our prior
              written consent such as contests, sweepstakes, barter, advertising
              and pyramid schemes, or the buying or selling of “virtual”
              products related to the Platform. Throughout this Terms of Use,
              Apex Learning Institute’s prior written consent means a
              communication coming from Apex Learning Institute’s Legal
              Department, specifically in response to Your request, and
              specifically addressing the activity or conduct for which You seek
              authorization;
            </li>
          </ul>
          <p>
            You shall not use any “deep-link”, “page-scrape”, “robot”, “spider”
            or other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Platform or any Content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Platform or any Content, to obtain or attempt to obtain any
            materials, documents or information through any means not purposely
            made available through the Platform. We reserve Our right to bar any
            such activity.
          </p>
          <p>
            You shall not attempt to gain unauthorized access to any portion or
            feature of the Platform, or any other systems or networks connected
            to the Platform or to any server, computer, network, or to any of
            the services offered on or through the Platform, by hacking,
            password “mining” or any other illegitimate means.
          </p>
          <p>
            You shall not probe, scan or test the vulnerability of the Platform
            or any network connected to the Platform nor breach the security or
            authentication measures on the Platform or any network connected to
            the Platform. You may not reverse look-up, trace or seek to trace
            any information on any other User of or visitor to Platform, or any
            other customer, including any account on the Platform not owned by
            You, to its source, or exploit the Platform or any service or
            information made available or offered by or through the Platform, in
            any way where the purpose is to reveal any information, including
            but not limited to personal identification or information, other
            than Your own information, as provided for by the Platform.
          </p>
          <p>
            You shall not make any negative, denigrating or defamatory
            statement(s) or comment(s) about Us or the brand name or domain name
            used by Us including the terms Apex Learning Institute, Apex
            Learning Institute.com, or otherwise engage in any conduct or action
            that might tarnish the image or reputation, of Apex Learning
            Institute or sellers on platform or otherwise tarnish or dilute any
            Apex Learning Institute’s trade or service marks, trade name and/or
            goodwill associated with such trade or service marks, trade name as
            may be owned or used by us. You agree that You will not take any
            action that imposes an unreasonable or disproportionately large load
            on the infrastructure of the Platform or Apex Learning Institute’s
            systems or networks, or any systems or networks connected to Apex
            Learning Institute.
          </p>
          <p>
            You agree not to use any device, software or routine to interfere or
            attempt to interfere with the proper working of the Platform or any
            transaction being conducted on the Platform, or with any other
            person’s use of the Platform.
          </p>
          <p>
            You may not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any message or transmittal You send
            to Us on or through the Platform or any service offered on or
            through the Platform. You may not pretend that You are, or that You
            represent, someone else, or impersonate any other individual or
            entity.
          </p>
          <p>
            You may not use the Platform or any content for any purpose that is
            unlawful or prohibited by these Terms of Use, or to solicit the
            performance of any illegal activity or other activity which
            infringes the rights of Apex Learning Institute and / or others.
          </p>
          <p>
            You shall at all times ensure full compliance with the applicable
            provisions of the Information Technology Act, 2000 and rules
            thereunder as applicable and as amended from time to time and also
            all applicable Domestic laws, rules and regulations (including the
            provisions of any applicable Exchange Control Laws or Regulations in
            Force) and International Laws, Foreign Exchange Laws, Statutes,
            Ordinances and Regulations (including, but not limited to Sales
            Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom
            Duty, Local Levies) regarding Your use of Our service and Your
            listing, purchase, solicitation of offers to purchase, and sale of
            products or services. You shall not engage in any transaction in an
            item or service, which is prohibited by the provisions of any
            applicable law including exchange control laws or regulations for
            the time being in force.
          </p>
          <p>
            You shall not engage in advertising to, or solicitation of, other
            Users of the Platform to buy or sell any products or services,
            including, but not limited to, products or services related to that
            being displayed on the Platform or related to us. You may not
            transmit any chain letters or unsolicited commercial or junk email
            to other Users via the Platform. It shall be a violation of these
            Terms of Use to use any information obtained from the Platform in
            order to harass, abuse, or harm another person, or in order to
            contact, advertise to, solicit, or sell to another person other than
            Us without Our prior explicit consent. In order to protect Our Users
            from such advertising or solicitation, We reserve the right to
            restrict the number of messages or emails which a user may send to
            other Users in any 24-hour period which We deems appropriate in its
            sole discretion. You understand that We have the right at all times
            to disclose any information (including the identity of the persons
            providing information or materials on the Platform) as necessary to
            satisfy any law, regulation or valid governmental request. This may
            include, without limitation, disclosure of the information in
            connection with investigation of alleged illegal activity or
            solicitation of illegal activity or in response to a lawful court
            order or subpoena. In addition, We can (and You hereby expressly
            authorize Us to) disclose any information about You to law
            enforcement or other government officials, as we, in Our sole
            discretion, believe necessary or appropriate in connection with the
            investigation and/or resolution of possible crimes, especially those
            that may involve personal injury.
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default TemrmsPolicy;
